import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Sophisticated linking simplified" />
    <div className="hero">
        <div className="hero-content-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 447.06 447.06" className="hero-logo" fill="currentColor"><title>Netclix Logo</title><rect x="81.8" width="56.69" height="28.35" /><rect x="81.8" y="85.04" width="56.69" height="56.69" /><rect x="81.8" y="170.08" width="56.69" height="113.39" /><rect x="166.84" y="28.35" width="56.69" height="56.69" /><rect x="166.84" y="141.73" width="56.69" height="28.35" /><rect x="308.57" y="255.12" width="56.69" height="28.35" /><rect x="308.57" y="141.73" width="56.69" height="56.69" /><rect x="308.57" width="56.69" height="113.39" /><rect x="223.53" y="198.43" width="56.69" height="56.69" /><rect x="223.53" y="113.39" width="56.69" height="28.35" /><path d="M0,399.37c0-9.1,8.25-26.76,32-26.76,22.9,0,32,17.66,32,26.76v45.9H46.05V403.85c0-7.25-5.51-14.49-14.06-14.49-9.39,0-14,7.47-14,14.49v41.42H0Z" /><path d="M148.5,409.54c0,1.34,0,4-.16,6.73H95.42c1.35,6.27,8.68,14.35,19.89,14.35,12.41,0,19.43-7.18,19.43-7.18l9,13.45a45.36,45.36,0,0,1-28.4,10.17c-21.53,0-38.12-16.44-38.12-37.22s15.1-37.23,35.58-37.23C132.2,372.61,148.5,387.71,148.5,409.54Zm-17.94-6.28c-.3-6.13-6-14.35-17.79-14.35-11.52,0-16.75,8.07-17.35,14.35Z" /><path d="M156,374.4h13.91V346.05h17.94V374.4h21.38v16.15H187.86v28.56c0,8.66,4.48,11.21,9.27,11.21a14.17,14.17,0,0,0,7.47-2.55l6.58,14.66a32.26,32.26,0,0,1-17,4.63c-13.76,0-24.22-10-24.22-25.12V390.55H156Z" /><path d="M274.75,421.65l11.81,12a38.34,38.34,0,0,1-29.3,13.45c-21.23,0-38.12-16.44-38.12-37.22s16.89-37.23,38.12-37.23a38,38,0,0,1,29,13.15l-12.4,11.07a22,22,0,0,0-16.6-7.47c-12.11,0-19.89,9.41-19.89,20.48s7.78,20.48,19.89,20.48A20.92,20.92,0,0,0,274.75,421.65Z" /><path d="M300.36,339H318.3v106.3H300.36Z" /><path d="M341.19,374.4h17.95v70.87H341.19Zm0-28.57h17.95v17.94H341.19Z" /><path d="M375.3,374.4h21.09l14.5,21.83,14.2-21.83h20.48L420.9,408.64l26.16,36.63H425.69l-16-24.22-16,24.22H373.36l26.31-36.63Z" /></svg>
            <div className="hero-text-wrapper">
                <div className="hero-text">
                    <h1>Netclix</h1>
                    <h2>Sophisticated linking simplified.</h2>
                    <h3>Coming Soon...</h3>
                </div>
            </div>
        </div>
        <div className="scroll-for-more-outer-wrapper">
            <div className="scroll-for-more-wrapper">
                <a href="#homepage-content">
                    <span id="scroll-msg">Scroll For More</span><div className="scroll-for-more-arrow">
                        <span className="glyphicon" aria-hidden="true">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-three-dots-vertical" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                            </svg>
                        </span>
                    </div>
                </a>
            </div>
        </div>
    </div>
  </Layout>
)

export default IndexPage
